@include media-breakpoint-down(md) {
  body {
    #header {
      .header__top {
        .logo-text-right, .logo-text-left {
          display: none;
        }
      }
    }
    .header--icons {
      display: none !important;
    }
    main {
      padding-top: 50px !important;
      .container-fluid {
        >.row {
          margin: 0px !important;
        }
      }
      .kontakticons {
        a {
          margin-bottom: 40px !important;
          i {
            justify-content: center;
            display: flex;
          }
        }
      }
      .tinyMCE {
        padding: 0px 10px !important;
      }
    }
    .mobile-footer {
      
    }
  }
}
@include media-breakpoint-down(sm) {
  body {
    font-size: 1.2rem !important;
  }
}

@include media-breakpoint-down(md) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  body {
    main {
      h1 {
        font-size: 45px !important;
        line-height: 0.9 !important;
        margin-bottom: 15px !important;
        margin-top: 30px !important;
      }
      h2 {
        font-size: 30px !important;
      }
    }
    @include media-breakpoint-down(sm) {
      main {
        .gutschein-bild {
          margin-top: -20px; margin-bottom: -20px;
          img {
            display: block; margin-left: auto; margin-right: auto;
          }
        }
      }
      #header {
        height: 50px !important;
        max-width: 100vw !important;

        .header__top {
          height: 70px !important;
        }

        #headerlogo {
          width: 100%;
          margin-left: 0px !important;
          a {
            margin-top: 10px !important;
          }

          .logo {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
          }
          .logo-text {
            padding-top: 15px !important;
            font-size: 15px !important;
          }
        .logo-text-left {

        }

          img {
            height: 50px !important;
          }
        }

        .navbar {
          .navbar-toggler {
            bottom: unset !important;
            top: 15px !important;
            left: unset !important;
            right: -20px !important;
            .navbar-toggler-icon {
              background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#333333' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !important;
            }
          }
        }
      }
      .bildergalerie--article {
        width: 100vw !important;
        height: 100vh !important;
        overflow: hidden;

        .teaser--bg {
          max-width: 100vw !important;
          max-height: 100vh !important;
        }
      }

      .teaser--main {
        .teaser {
          min-height: 280px !important;
          max-height: 450px !important;
          article {
            display: none !important;
            left: 30px !important;
            h1 {
              font-size: 20px !important;
            }
            p {
              font-size: 16px !important;
            }
          }
        }
      }

      footer {
        .kontakticons-full {
          .comp1, .comp2 {
            padding-left: 30px !important;
            padding-bottom: 30px !important;
          }
        }
      }
    }
    header {
      .shd-box-main {
        .shd-box:before {
          height: 100px !important;
        }
      }
      .mobile-shadow {
        width: 100%;
        position: fixed;
        height: 70px;
        bottom: unset !important;
        background: transparent;
        display: flex !important;
        box-shadow: 0px 0px 10px #000;
        z-index: 9999;
        pointer-events: none !important;
      }
      .navbar-toggler {
        position: fixed;
        bottom: 20px;
        top: unset;
        left: 50%;
        max-width: 60px !important;
        transform: translateX(-50%);
        border: 1px solid #fff !important;
      }
      .header__top {
        .logo-left {
          display: none !important;
          .logo-signet {
            width: 80px !important;
            height: 110px !important;
          }
        }
      }
      .logo-right {
        position: fixed !important;
        left: -20px !important;
        right: unset !important;
        bottom: unset !important;
        top: 5px !important;
        transform: unset !important;
        background: unset !important;
        width: 30% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding-left: 10px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        height: 80px !important;
        border-right: unset !important;
        max-width: 100vw !important;
        .logo-comp {
          top: 2px;
          position: absolute;
          width: 130px !important;
          .logo-comp-icons {
            width: 90% !important;
            a {
              i {
                svg {
                  path {
                    fill: #666 !important;
                  }
                }
              }
            }
          }
          .logo-comp-font {
            color: #fff;
          }
        }
        img, svg {
          max-width: 30px !important;
          .st0 {
            fill: #fff !important;
          }
        }
      }
      nav {
        height: auto !important;
        .nav-shadow {
          img {
            height: 25px !important;
          }
        }
        .navbar-nav {
          padding-bottom: 20px !important;
          .navbar-nav {
            padding-bottom: 0px !important;
          }
        }
        .nav-item {
          margin-bottom: 0px;
          padding-bottom: 0px !important;
          padding-top: 5px !important;
          .nav-link {
            padding: 0px !important;
          }
          .nav-item {
            padding-top: 0px !important;
            .nav-link {
              font-size: 20px !important;
            }
          }
        }
        .navbar-nav {
          box-shadow: 0px 0px 10px #000;
        }
        .show {
          display: block !important;
          right: 0px !important;
          box-shadow: 0px 10px 10px #000;
        }
        #navbarSupportedContent {
          background: #fff !important;
          margin-top: 0px !important;
          padding-top: 0px !important;
          top: 70px !important;
          bottom: unset !important;
          max-width: 230px;
          right: -230px;
          left: unset;
          transform: none !important;
          max-height: calc(100vh - 180px) !important;
          overflow-x: scroll !important;
          transition: right 0.3s ease-in-out;
          .navbar-nav {
            box-shadow: none !important;
            margin-top: 0px !important;
            .nav-item {
              border: none !important;
              .dropdown-toggle {
                pointer-events: none !important;
              }
              a {
                color: #666;
                text-shadow: none !important;
              }
              .dropdown-menu {
                display: block;
                opacity: 1;
                position: relative;
                margin-top: unset !important;
                margin-bottom: unset !important;
                top: unset;
                box-shadow: none !important;
                a {
                  font-size: 15px !important;
                  pointer-events: all !important;
                }
              }
            }
          }
          img {
            display: none !important;
          }
        }
        .navbar-collapse {
          position: fixed;
          bottom: 80px !important;
          top: unset !important;

          height: auto !important;
          width: 100% !important;
          background: unset !important;
          padding: 20px 0px 20px;
          padding-bottom: 0px !important;
          ul {
            flex-direction: column !important;
            li {
              width: 100% !important;
              padding: 20px 0px;
              a {
                font-size: 22px !important;
              }
              ul {
                li {
                  padding: 0px;
                  margin: 0px;
                  text-transform: none !important;
                }
              }
            }
          }
        }
      }
    }
    main {
      .infobox {
        .box {
          margin: unset !important;
          padding: unset !important;
          .infobox-box {
            margin-left: 0px !important;
            margin-right: 0px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
          }
        }
        .infobox-box {
          margin-bottom: 0px !important;
          padding-bottom: 15px !important;
          .box-firstsection {
            h2 {
              margin-top: 10px !important;
              margin-bottom: 0px !important;
              line-height: 0.9 !important;
            }
            .deviderinfobox {
              margin-top: 10px !important;
              margin-bottom: 10px !important;
            }
            hr {
              margin-top: 10px !important;
            }
            h3 {
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
    .news-startseite {
      .news {
        width: calc(100% - 20px);
        margin-bottom: 30px;
      }
    }
    footer {
      min-height: 520px !important;
      padding-bottom: 100px !important;
      .footer-logo-div {
        .text-right, .text-left {
          display: none !important;
        }
      }
      .footerdevider {
        width: calc(100% - 40px);
        margin-left: 20px;
      }
      .site-map {
        margin-top: 50px !important;
      }
      .kontakticons {
        display: flex;
        flex-direction: column;
      }
      .footer--silhouette {
        i {
          svg {
            height: 100vw;
          }
        }
      }
    }
  }
}