.bildmittext-box {
  margin-bottom: 20px;
  .bildbox {
    border: 10px solid #fff;
    padding: 0px !important;
    box-shadow: 0 0 5px #e5e5e5;
    .bild {
      position: relative;
      width: 100%;
      height: 100%;
      picture {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          position: relative;
          width: 100%;
        }
      }
    }
  }
  .bild {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 5px rgba(0,0,0,.25) !important;
    margin-bottom: 20px;
    picture {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        position: relative;
        width: 100%;
      }
    }
  }
}