.site-map {
  display: flex;
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  li {
    h5 {
      padding-bottom: 0px;
      margin-bottom: 0px;
      a {
        line-height: 30px;
        font-size: 30px;
        color: #ffffff !important;
        white-space: normal;
        text-shadow: -1px -1px 0 #282828;
        font-weight: bold;
        font-family: "Yanone Kaffeesatz";
      }
    }
    a {
      line-height: 21px;
      font-size: 21px;
      color: #fff !important;
      white-space: normal;
      text-shadow: -1px -1px 0 #282828;
      font-weight: 400;
      font-size: 21px;
    }
    .site-map--lev2 {
      list-style: none;
      margin-left: 0px;
      padding-left: 0px;
      padding-bottom: 10px;

      li {
        a {
          font-weight: 200 !important;
          font-size: 20px;
          font-family: "Roboto Condensed";
        }
      }
    }
  }
}
.sitemap--nebeneinander {
  flex-direction: row;
  justify-content: space-around;
}
.sitemap--untereinander {
  flex-direction: column;
}