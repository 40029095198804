footer {
  z-index: 60;
  background: transparent;
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  min-height: 50px;
  .kontakticons {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
}