/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/yanone-kaffeesatz-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/yanone-kaffeesatz-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/yanone-kaffeesatz-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/yanone-kaffeesatz-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/yanone-kaffeesatz-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/yanone-kaffeesatz-v16-latin-regular.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* yanone-kaffeesatz-600 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/yanone-kaffeesatz-v16-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/yanone-kaffeesatz-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/yanone-kaffeesatz-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/yanone-kaffeesatz-v16-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/yanone-kaffeesatz-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/yanone-kaffeesatz-v16-latin-600.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* yanone-kaffeesatz-700 - latin */
@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/yanone-kaffeesatz-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/yanone-kaffeesatz-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/yanone-kaffeesatz-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/yanone-kaffeesatz-v16-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/yanone-kaffeesatz-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/yanone-kaffeesatz-v16-latin-700.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-condensed-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-condensed-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v19-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v19-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-condensed-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v19-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-condensed-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-condensed-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v19-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v19-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  font-display: swap;
}