$primary-color: #575656;
$primary-color-light: rgb(153, 0, 0);
$primary-color-dark: rgb(153, 0, 0);
$secondary-color: #b3b2b2 !default;
$secondary-color-dark: #004750 !default;
$header--height: 125px;
$header--bg-color: #ddc8a2;

$nav--bg-color: #004750;
$nav-main--font-family: 'Yanone Kaffeesatz';
$nav-main--font-weight: 900;
$nav-main--font-size: 20px;

$footer--bg-color: #eb2323;
$font-family-base: 'Roboto Condensed';
$h1--font-family: 'Yanone Kaffeesatz';
$h2--font-family: $h1--font-family;
$font-weight-base: 400;
$headings-font-weight: 400;
$font-size-base: 1.2rem !important;

$farbe-1: #666666;
$farbe-1-hover: rgba(51, 51, 51, 0.5);
$kontakt-icons--a-color-hover: rgba(51, 51, 51, 0.5);
$farbe-2: #dfaf46;
$farbe-3: #999;


$kontakt-icons--a-color-dark: #575656;
$kontakt-icons--a-color-dark--hover: #e1e1e1;
$kontakt-icons--a-color: $kontakt-icons--a-color-dark;
$kontakt-icons--a-color-hover: $kontakt-icons--a-color-dark--hover;


$link-color: $farbe-1;

$farbe-tct: #666666;
$farbe-tct-hover: #eb2323;

$a--color: #000000;
$a-hover--color: $farbe-1-hover;

$selection-background-color: #999;


@import "modules/header.scss";
@import "modules/kontakt_icons";
//@import "modules/logocloud";
@import "modules/teaser";
@import "modules/teaser-halb";
@import "navs/nomenue";
@import "modules/video";
@import "modules/infobox";
@import "modules/news";
@import "modules/sitemap";
@import "modules/iconsmitlinks";
@import "modules/bilderslider";
@import "modules/bildmittext";
@import "modules/comp_info";
@import "navs/topnav";
@import "modules/busreisen";

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1200px
);

html, body {
  overscroll-behavior-y: none;
}
body {
  font-size: 15px !important;
  font-weight: 200 !important;
  overscroll-behavior: none !important;
  color: #575656 !important;
  font-family: "Roboto Condensed" !important;
  font-display: swap;

  .mobile-footer {
    display: none;
  }
  .modal-header {
    border-bottom: 0px;
    button {
      padding: 0px !important;
      background: none !important;
      border: 0px !important;
      margin-top: -20px !important;
      margin-right: -10px !important;
      span {
        font-size: 50px !important;
      }
    }
  }
  .modal-content {
    margin-top: 50%;
  }
  .modal-body {
    h1 {
      margin-top: 0px;
    }
  }

  h1 {
    color: #575656;
    font-weight: 600 !important;
  }
  h2 {
    color: #b3b2b2;
  }
  header {
    position: fixed !important;
    z-index: 999;
    background: transparent !important;
    width: 100%;
    .shd-box-main {
      top: 0px !important;
      height: 180px;
      .shd-box:before {
        top: -47px !important;
        box-shadow: 0px 15px 30px #333;
        width: 150%;
        transform: rotate(0deg) !important;
      }
    }
    .header__top {
      display: flex;
      position: relative;
      height: 120px;
      background: url("../../../index.php?rex_media_type=containerbg2&rex_media_file=xpattern_papier.png") #fff;
      background-repeat: repeat-x !important;
      z-index: 9999;
      .logo-left {
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        width: 100%;
        height: 30px;
        background: #fff;
        z-index: 1;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
      }
      .logo-right {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        height: 50px;
        display: flex;
        margin-right: 10px;
        border-right: 1px solid #e5e5e5;
        width: 100%;
        justify-content: flex-end;
        .comp1 {
          padding-right: 0px !important;
          margin-right: 0px !important;
          .logo-comp-icons {
            text-align: right;
            a:hover {
                color: #999 !important;
            }
          }
          a {
            color: #666666;
          }
          a:hover {
            color: $kontakt-icons--a-color-hover !important;
          }
        }
        .logo-comp {
          width: 150px;
          display: flex;
          flex-direction: column;
          margin: 0px 20px;
          .logo-comp-font {
            width: 100%;
            position: relative;
            text-align: center;
            border-bottom: 1px solid #e5e5e5;
          }
          .logo-comp-icons {
            width: 100%;
            img, svg {
              height: 30px;
              margin: 5px;
            }
          }
        }
        a {
          color: $farbe-tct;
          transition: color 0.3s;
          img, svg {
            margin: 0px 10px;
            height: 50px;
          }
        }
        a:hover {
          color: $farbe-tct-hover;
        }
      }
    }
    .header__largelogo {
      background: transparent;
      img {
        width: 100%;
      }
    }
    .navbar-toggler {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: 9999;
      background: #fff !important;
      color: #b3b1b2;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(179,177,178, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
    .header--icons {
      display: none !important;
    }
    @include media-breakpoint-up(lg) {
      .hidden-lg {
        display: none !important;
      }
    }
    .nav-shadow {
      pointer-events: none;
      position: absolute;
      top: 0px;
      width: 100%;
      img {
        position: absolute;
        top: 0px;
        width: 100%;
      }
    }
    nav {
      margin-top: -37px;
      padding: 0px !important;
      font-size: 30px;
      font-family: $font-family-base;
      color: #117179;
      background: transparent !important;
      text-transform: uppercase;
      flex-direction: row;
      justify-content: space-between;
      z-index: 99999;
      height: 75px !important;
      #navbarSupportedContent {
        background: linear-gradient(180deg, rgba(118,118,118,0.95) 0%, rgba(118,118,118,1) 100%) !important;
        transition:visibility 0.3s linear,opacity 0.3s linear;
        border-radius: 5px;
        position: fixed;
        z-index: 9999;
        left: 50%;
        width: 1200px;
        transform: translateX(-50%);
        ul {
          width: 100% !important;
          height: auto;
          margin-top: 10px;
          margin-bottom: 10px;
          justify-content: center;
          align-items: baseline;
          text-shadow:  1px 1px #fff;
          li {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            border-right: 1px solid #727272;
            border-left: 1px solid #575656;
            a {
              color: #fff;
              font-weight: 600;
              text-shadow: -1px -1px #333333;
              font-size: 30px;
              text-transform: uppercase;
            }
            a:hover {
              color: #b3b2b2 !important;
            }
            .dropdown-menu {
              position: absolute;
              box-shadow: 0px 0px 10px #666;
              border-radius: 0px 0px 5px 5px;
              a {
                color: #767676;
                font-size: 20px;
                font-weight: 400;
                font-family: "Roboto Condensed";
                text-transform: none !important;
                transition: color 0.3s !important;
              }
            }
          }
          :last-child {
            border-right: 0px;
          }
          :first-child {
            border-left: 0px;
          }
        }
      }
      .collapsing {
        height: unset !important;
        visibility:visible;
        opacity:1;
      }
      .navbar-nav {
        flex-direction: row !important;
        justify-content: space-evenly;
        height: 100%;
      }
    }
    #headerlogo {
      display: flex;
      justify-content: center !important;
      position: relative;
      z-index: 99;
      height: 100%;
      top: 0px;
      .logo-text {
        padding-top: 45px;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
        font-family: "Yanone Kaffeesatz";
        font-size: 20px;
        color: #575656;
      }
      .logo-text-right {
        margin-left: -20px;
        font-family: "Yanone Kaffeesatz";
        font-size: 20px;
        color: #575656;
      }
      .logo {
        height: 100px;
        a {
          display: flex;
          margin-top: 10px;
          height: 100%;

          img {
            height: 70%;
            margin-top: 0px;
            width: unset !important;
          }
        }
      }
    }
  }
  .header--icons {
    display: flex !important;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999;

    .kontakticons {
      font-size: 30px !important;
      padding-top: 25px !important;
      padding-right: 25px !important;
    }
    a:hover {
      color: #fff4e0 !important;
    }
    a {
      display: flex;
      color: #fff4e0 !important;
      i {
        transform: unset !important;
        padding: 0px 10px 10px;
        width: 40px !important;
        left: unset !important;

        img, svg {
          height: 30px !important;
          vertical-align: top !important;
        }
      }
    }
  }
  .modal {
    .modal-header {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .modal-body {
      margin-top: 0px;
      padding-top: 0px;
    }
    h1 {
      font-size: 40px;
      font-weight: 400;
      //color: rgb(235,35,35);
      margin-top: 60px;
      margin-top: 0px !important;
    }
    h2 {
      font-size: 25px;
      font-weight: 400;
      color: $primary-color;
    }
  }
  main {
    overscroll-behavior: none !important;
    padding-top: 120px;
    background: #ffffff !important;
    z-index: 2;
    .gutschein-bild {
      margin-top: -40px; margin-bottom: -60px;
      img {
        display: block; margin-left: auto; margin-right: auto;
      }
    }
    h1 {
      font-size: 40px;
      font-weight: 400;
      color: $primary-color;
      margin-top: 60px;
      text-shadow: 1px 1px #fff;
    }
    h2 {
      font-size: 30px;
      font-weight: 400;
      text-shadow: 1px 1px #fff;
    }
    h4 {
      text-align: center;
      font-weight: 700;
      font-size: 25px;
      font-family: $font-family-base;
    }
    .tinyMCE:first-child {
      h1 {
        margin-top: 60px;
      }
    }
    .tinyMCE {
      ul {
        margin-left: 0px;
        padding-left: 0px;
        li {
          list-style: none;
          background-image: url('../../../media/icon_check.svg');
          background-repeat: no-repeat;
          padding-left: 30px;
          background-position: left 6px;
          background-size: 20px;
        }
      }
    }
    a {
      font-weight: 400 !important;
      transition: 0.3s color;
    }
    a:hover {
      color: #b3b2b2 !important;
    }
    .kontakticons {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
      i {
        width: unset !important;
        img,svg {
          height: 100px;
        }
      }
    }
    .tinyMCEbackground-1,
    .tinyMCEbackground-2,
    .tinyMCEbackground-3 {
      height: 100%;
      padding: 30px;
      color: #fff !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      hr {
        width: 100%;
      }
      h2 {
        color: #fff !important;
      }
    }
    hr {
      margin-bottom: 30px;
    }
    .tinyMCEbackground-1 {
      background: rgba(0,99,115, 1.0);
    }
    .tinyMCEbackground-2 {
      background: rgba(67,177,179, 1.0);
    }
    .tinyMCEbackground-3 {
      background: rgba(234,91,143, 1);
    }
    .grid-container {
      z-index: 99;
      background-color: #ffffff;
    }
    .container-fluid {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  .container-color-1:before,
  .container-color-2:before,
  .container-color-3:before,
  .container-color-4:before {
    font-weight: bold;
    font-size: 300px;
    line-height: 286px;
    margin-left: -10px;
    position: absolute;
  }
  .container-color-1, .container-color-2, .container-color-3 {
    position: relative;
    display: flex;
    >div {
      position: relative;
    }
  }

  .container-color-1 {
    background: url("../../../index.php?rex_media_type=containerbg&rex_media_file=xpattern_leder.png") #ffffff !important;
    background-repeat: repeat-x !important;
  }
  .container-color-1:before {
    color: #f2f2f1;
  }

  .container-color-2 {
    background: url("../../../index.php?rex_media_type=containerbg&rex_media_file=xpattern_gewebe.png") repeat-x #b3b3b3 !important;
    color: #ffffff !important;
    h1 {
      color: #fff !important;
      text-shadow: -1px -1px #575757;
    }
    h2 {
      color: #e5e5e5 !important;
      text-shadow: -1px -1px #575757;
    }
    .infobox {
      svg,img {
        color: #fff !important;
        width: 32px;
        height: auto;
        transition: 0.3s color;
      }
    }
    a {
      color: #fff;
    }
    a:hover {
      color: #b2b2b2;
    }
  }
  .container-color-2:before {
    color: #cbbc97;
  }

  .container-color-3 {
    background: url("../../../index.php?rex_media_type=containerbg&rex_media_file=xpattern_papier.png") repeat-x;
    background-color: #fff;
    .uppercase {
      color: $header--bg-color !important;
    }
  }
  .container-color-4 {
    background: transparent !important;
  }
  .container-color-3:before {
    line-height: 282px;
  }

  .header--text {
    left: 200px !important;
    top: 80px !important;
    transform: none !important;
    color: $header--bg-color;
  }
  footer {
    text-align: center;
    color: #343434;
    background: url('../../../index.php?rex_media_type=containerbg&rex_media_file=xpattern_stoff.png') #575757 !important;
    background-repeat: repeat-x !important;
    z-index: 999999;
    min-height: 500px !important;
    a:hover {
      color: #b2b2b2 !important;
    }
    .comp1-data {
      width: 100%;
    }
    .footerdevider {
      width: 100%; height: 0px; border-top: solid 1px #b3b3b3; border-bottom: solid 1px #575757; margin-top: 20px; margin-bottom: 10px;
    }
    .footer--copyright {
      color: #fff !important;
      a {
        color: #fff !important;
      }
    }
    .footer--shadow {
      position: absolute;
      top: 0px;
      width: 100%;
      picture {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        img {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 30px;
        }
      }
    }
    h1, h2, h3, h4, h5, h6 {
      color: #fff !important;
    }
    a {
      color: #343434 !important;
    }
    .footer-logo-div {
      .row {
        .text-right, .text-left {
          margin-top: 50px;
          color: #b3b2b2;
          font-family: "Yanone Kaffeesatz";
          font-weight: 400;
        }
        .text-left {
          margin-left: -50px;
        }
        .text-right {
          margin-right: -20px;
        }
        .logo-footer {
          position: relative;
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          z-index: 999;
          img {
            width: 200px;
          }
        }
      }
    }
    .footer--silhouette {
      position: absolute;
      right: 0px;
      bottom: 0px;

      i {
        svg {
          height: 33vw;
          width: auto;
          max-height: 800px !important;
          .st0 {
            fill: #404040;
          }
        }
      }
      .footer--copyright {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: -60px;
        color: #333333;
        a {
          font-weight: 200 !important;
          color: #333333 !important;
        }
      }
    }
    .footer--imprint {
      position: relative;
      padding: 20px;
      background: #666666;
      color: #fff !important;
      a {
        font-weight: 200 !important;
        color: #fff !important;
      }
    }
    .kontakticons {
      color: #fff !important;
      a {
        color: #fff !important;
        transition: color 0.3s;
        svg, img {
          color: #fff !important;
          transition: color 0.3s;
        }
        .text {
          transition: color 0.3s;
        }
      }
      a:hover {
        svg, img {
          color: #b2b2b2 !important;
        }
        .text {
          color: #b2b2b2 !important;
        }
      }
    }
  }

  .visible img {
    opacity: 1;
    transform: translateY(0px) !important;
  }
  .bildergalerie--article {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    .teaser--bg {
      width: 100%;
      height: 100%;
      position: fixed;
      opacity: 0;
      transition: opacity 0.6s;
      display: flex;
      object-fit: cover;
      picture {
        height: 100%;
        width: 100%;
        object-fit: cover;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .active {
      opacity: 1;
    }
  }

  #table-rex_reiseanfrage {
    .btn {
      width: 100%;
      color: #fff;
      font-size: 25px;
    }
  }
}