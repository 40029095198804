.busreisen-listing {
  .reise {
    background: #F5F5F5;
    padding: 20px 20px 20px 20px;
    margin-bottom: 30px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    .subheaddline {
      font-weight: bold;
      text-align: center;
    }
    .tage {
      position: absolute;
      left: -27px;
      top: 10px;
      font-size: 22px;
      font-weight: 700;
      background: #990000;
      color: #fff;
      width: 110px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      text-align: center;
    }
    .reise-devider {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      padding: 0px 20px;
      .preis {
        font-size: 22px;
      }
      a {
        position: absolute;
        right: 20px;
        bottom: 0px;
      }
    }
  }
}

.busreisen-detail {
  .preise {
    background: #F5F5F5;
    padding: 20px 20px 20px 20px;
    margin-bottom: 30px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
  }
}