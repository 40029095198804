//@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Yanone+Kaffeesatz:wght@200;300;400;700&display=swap');
@import "fonts";

$primary-color: #009900 !default;
$primary-color-light: #00ff00 !default;
$primary-color-hover: $primary-color-light !default;
$primary-color-dark: #006600 !default;
$secondary-color: #0000ff !default;
$secondary-color-dark: #000066 !default;
$a--color: #000000 !default;
$a-hover--color: #cccccc !default;
$selection-background-color: #ff0000 !default;

$h1--font-family: 'Arial' !default;
$h2--font-family: $h1--font-family !default;

@import "modules/parallax-bild";
// Required
@import "_import";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import "design";

$theme-colors: (
  "secondary": $secondary-color,
  "primary": $primary-color,
  "primary-dark": $primary-color-dark,
  "primary-light": $primary-color-light,
  "primary-light-hover": $primary-color-hover,
  "btn-primary-text-hover": #fff,
  "btn-primary-text": #212529,
  "btn-primary-bg": #ffa401,
  "body-bg-hover": #e0e0e0
);

$dropdown-bg: rgba(255,255,255, 0.7);
$dropdown-link-hover-bg: none;
$dropdown-link-color: theme-color('primary-light');

//$headings-font-family: 'Montserrat', $font-family-sans-serif;
$font-family-base: 'Barlow Condensed', $font-family-sans-serif;

@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
//@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
//@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
//@import "../node_modules/bootstrap/scss/breadcrumb";
//@import "../node_modules/bootstrap/scss/pagination";
//@import "../node_modules/bootstrap/scss/badge";
//@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";


::selection {
  text-shadow: none;
  background: $selection-background-color;
}

a {
  color: $a--color;
  transition: color 0.3s;
  font-weight: bold;
}

a:hover {
  color: $a-hover--color !important;
  text-decoration: none;
}

h1 {
  margin-top: 35px;
  text-align: center;
  color: theme-color('primary');
  text-transform: none;
  font-size: 80px;
  font-family: $h1--font-family;
  font-weight: 300;
  margin-bottom: 0px;
}

h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #706f6f;
  font-size: 40px;
  font-family: $h2--font-family;
  font-weight: 600;
}

h3 {
  font-size: 1.7rem;
}

html {
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-feature-settings: "kern";
  overflow: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  @import "header.scss";
  main {
    z-index: 50;
    flex: 1;
    width: 100%;
    min-height: calc(100vh - 164px);
    background: #ffffff;
    position: relative;
    .grid-container:last-child {
      padding-bottom: 80px;
    }
  }

  @import "footer";

  .btn-primary {
    background-color: theme-color("btn-primary");
    color: theme-color("btn-primary-text");
  }
}

.grid-container {
  position: relative;
  .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%;
  }
  .content--shadow:before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0.35714rem;
    bottom: 1.07143rem;
    width: 50%;
    max-width: 21.42857rem;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 15px 10px rgba(0,0,0,0.2);
    z-index: -1;
  }
  .container-fluid-shadow {
    overflow: hidden;
    position: relative;
    z-index: 3;
  }
}
.shdbox {
  // mix-blend-mode: multiply;
}
.shd-box-main {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  height: 150px;
  .shd-box {
    width: 100%;
    height: 125px;
  }
  .shd-box:before {
    content: " ";
    box-shadow: 0 15px 25px rgba(200,200,200,1);
    transform: rotate(-0.5deg);
    top: -150px;
    left: -15px;
    width: 100%;
    position: absolute;
    height: 150px;
    mix-blend-mode: multiply;
    transform-origin: left bottom;
  }
}

@import "sizes/xl";
@import "sizes/l";
@import "sizes/lg";
@import "sizes/md";
@import "sizes/sm";
@import "sizes/xs";