.comp_info {
  color: #fff;
  font-family: "Roboto Condensed";
  font-size: 20px;
  font-weight: 400;
  text-shadow: -1px -1px #282828;
  .comp1-name {
    font-size: 30px !important;
    font-weight: 600;
    font-family: "Yanone Kaffeesatz";
  }
  a {
    color: #fff !important;
  }
}