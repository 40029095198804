$navbar--bg: #000000 !default;
$navbar--bg-1: #ff00ff !default;
$navbar--bg-2: #00ff00 !default;
$navbar--bg-1-pos: 0% !default;
$navbar--bg-2-pos: 100% !default;
$navbar--bg-direction: horizontal !default;
$navbar--height: 50px !default;

$navbar--font-size: 16px !default;
$navbar--text-transform: initial !default;
$navbar--color-active: #fff !default;
$navbar--color: #000 !default;
$navbar--color-hover: #ff0000 !default;
$navbar--width: inital !default;
$navbar--padding: 0px 0px !default;
$navbar--font-family: Arial !default;

$header-height: 0px !default;
$navbar--logo-top: '' !default;
$navbar--logo-left: '' !default;

$logo--height: 30px !default;

header{
  .header--shadow {
    position: absolute;
    top: 126px;
  }
}

.navbar {
  padding: 15px;
  width: 100%;
  height: $navbar--height;
  background-color: $navbar--bg;
  top: $header-height;
  //transition: top 0.3s;

  .navbar-toggler-icon {
    background: str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !important;
  }

  .socialmediaicons {
    position: absolute;
    top: -165px;
    left: 0px;
    padding: 10px;
    z-index: 9999;
    color: #a87f66;
    transition: all 0.3s;
    i {
      color: #a87f66;
      transition: color 0.3s;
      svg {
        height: 25px;
      }
      img {
        height: 25px;
      }
    }
  }
  .text-light {
    i {
      color: #ffffff !important;
    }
  }

  .socialmediaicons-scrolled {
    color: #ffffff;
    position: absolute;
    top: -50px;
  }

  .btn {
    margin-left: 10px;
    background: theme-color("primary");
    color: #ccc;
    border: 0px;
  }

  .btn-secondary:hover {
    background: theme-color("primary-dark");
  }

  .logo {
    height: $logo--height;
    position: absolute;
    top: $navbar--logo-top;
    left: $navbar--logo-left;
  }

  .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    font-size: $navbar--font-size;
    font-family: $navbar--font-family;
    text-transform: $navbar--text-transform;
    width: $navbar--width;
    padding: $navbar--padding;
    justify-content: space-between;
    height: $navbar--height;

    .nav-item {
      display: flex;
      justify-content: center;
      position: relative;
      flex: 0 0 1px;
      a {
        color: $navbar--color;
        display: flex;
        align-items: center;
        text-shadow: -0.5px -0.5px #ccc;
        font-family: "Yanone Kaffeesatz";
        font-weight: 600;
        transition: color 0.3s;
      }

      >a:hover {
        opacity: 0.5;
        color: #fff !important;
      }

      .dropdown-toggle::after {
        content: unset;
      }
      .show {
        pointer-events: auto;
        opacity: 1 !important;
      }
    }

    .dropdown-toggle::after {
      content: unset;
    }

    .active {
      color: $navbar--color-active !important;
      a {
        color: $navbar--color-active !important;
      }
    }

    .submenue {
      display: flex;
      flex-direction: column;
      font-size: 20px;

      .dropdown-item {
        background: none;
        font-size: 30px;
      }
    }
  }


  .dropdown-menu {
    left: unset !important;
    top: 70px;
    display: block;
    opacity: 0;
    pointer-events: none;
    flex-direction: column;
    border: 0px;
    box-shadow: 0px 0px 5px #999;
    width: 100%;
    border-radius: 0px;
    background: rgba(255,255,255,0.9);
    //transform: translateY(-10px);
    transition: opacity .3s !important;

    .dropdown-item {
      font-size: 30px;
      text-align: center;
      text-transform: none;
      font-weight: 600;
      justify-content: center;
      text-shadow: 1px 1px #ffffff !important;
    }

    .submenue-second {
      font-size: 20px;
      padding: 0px 80px;
    }
  }
  .show {
    display: flex;
    >.nav-link {
      color: #fff !important;
    }
  }

  .navbar-toggler {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999999;
  }
}

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: #ffffff;
}
