$kontakt-icons--a-color-hover: #00ff00 !default;
$kontakt-icons--a-color: #00cc00 !default;

$kontakt-icons--a-color-dark: #ff0000 !default;
$kontakt-icons--a-color-hover-dark: #cc0000 !default;
.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  z-index: 10;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $kontakt-icons--a-color;
    transition: color 0.3s;
  }
  a:hover {
    color: $kontakt-icons--a-color-hover;
  }
  i {
    padding: 0px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
    svg {
      height: 70px;
    }
    img {
      height: 70px;
    }
  }
  .text {
    font-size: 16px;
    text-align: center;
  }
}
body {
  main {
    .comp1, .comp {
      a {
        i, div, svg {
          color: $kontakt-icons--a-color;
          transition: color 0.3s;
          path {
            transition: color 0.3s;
            color: $kontakt-icons--a-color !important;
          }
        }
      }
      a:hover {
        i, div, svg {
          color: #999 !important;
          path {
            color: #999 !important;
          }
        }
      }
    }
    .comp2 {
      .logo {
        max-width: 250px !important;
      }
      a {
        i, div {
          color: #0e4194;
          transition: color 0.3s;
        }
      }
      a:hover {
        i, div {
          color: #2b62c8 !important;
        }
      }
    }
  }
}

.dunkle-icons {
  a {
    color: $kontakt-icons--a-color-dark !important;
  }

}

.kontakticons-full {
  a {
    display: flex;
    flex-direction: row;
    i {
      margin-right: 10px;
      img,svg {
        height: 20px;
        max-width: 150px;
      }
    }
  }
  .logo {
    height: 70px;
    max-width: 170px;
  }
  .comp1, .comp {
    a {
      i {
        color: $kontakt-icons--a-color-hover;
        transition: color 0.3s;
      }
    }
    a:hover {
      i {
        color: $kontakt-icons--a-color-hover !important;
      }
    }
  }
  .comp2 {
    .logo {
      max-width: 250px !important;
    }
    a {
      i {
        color: #0e4194;
        transition: color 0.3s;
      }
    }
    a:hover {
      i {
        color: #2b62c8 !important;
      }
    }
  }
}